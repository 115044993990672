<script setup lang="ts">
import CookieDialog from '@/components/common/cookieControl/cookieDialog/cookieDialog.vue'
import { onMounted, ref } from 'vue'
import Cookie from '@/utils/cookie'

const needsCookieConsent = ref(true)
const analyticsCookie = ref(false)
const marketingCookie = ref(false)

const useCookiePreferences = (cookiePreferences: {
  googleAnalytics: boolean
  marketing: boolean
}) => {
  const expiryDate = new Date()
  expiryDate.setDate(expiryDate.getDate() + 365)
  const expires = expiryDate.toUTCString()
  const acceptedCookies = []
  Object.keys(cookiePreferences).forEach((key) => {
    if (cookiePreferences[key]) {
      acceptedCookies.push(key)
    }
  })
  const cookieString = `cookieControl~${acceptedCookies.join('~')}`
  const joinedCookieString = `cookieControl${acceptedCookies.join('')}`
  const actualEnv = import.meta.env.VITE_CUSTOM_ENV
  let domain = 'meskanieletu.mavera.sk'
  switch (actualEnv) {
    case 'production':
      domain = 'meskanieletu.sk'
      break
    case 'staging':
      domain = 'meskanieletu.mavera.tech'
      break
    default:
      domain = 'meskanieletu.mavera.sk'
  }

  if (cookieString.length === 0) {
    // Remove from both domains to ensure it's completely cleared
    Cookie.remove('ncc_c', { domain, path: '/' })
    Cookie.remove('ncc_e', { domain, path: '/' })
  } else {
    // Set two cookies types - one for immediate domain and one for parent domain
    document.cookie = `ncc_c=${joinedCookieString}; expires=${expires}; domain=${domain}; path=/; SameSite=Strict; Secure=${import.meta.env.VITE_CUSTOM_ENV === 'production'}`
    document.cookie = `ncc_e=${cookieString}; expires=${expires}; domain=${domain}; path=/; SameSite=Strict; Secure=${import.meta.env.VITE_CUSTOM_ENV === 'production'}`
  }

  analyticsCookie.value = cookiePreferences.googleAnalytics
  marketingCookie.value = cookiePreferences.marketing
  needsCookieConsent.value = false
  updateConsent(cookieString)
}

const getCookieValues = () => {
  const cookie: string | null = Cookie.get('ncc_e')
  if (!cookie) {
    return
  }
  updateConsent(cookie)
}

const updateConsent = (cookie: string) => {
  needsCookieConsent.value = false
  const cookieParts = cookie.split('~')
  cookieParts.forEach((part) => {
    switch (part) {
      case 'googleAnalytics':
        analyticsCookie.value = true
        break
      case 'marketing':
        marketingCookie.value = true
        break
    }
  })

  gtag('consent', 'update', {
    ad_storage: analyticsCookie.value ? 'granted' : 'denied',
    analytics_storage: analyticsCookie.value ? 'granted' : 'denied',
    ad_user_data: marketingCookie.value ? 'granted' : 'denied',
    ad_personalization: marketingCookie.value ? 'granted' : 'denied'
  })
}

onMounted(() => {
  getCookieValues()
})
</script>

<template>
  <v-container class="pa-6 cookie-banner" fluid v-if="needsCookieConsent">
    <v-row justify-start>
      <v-col>
        <h2 class="text-white highlighted">{{ $t('cookie.title') }}</h2>
        <p class="text-white mt-2 paragraph">
          {{ $t('cookie.description') }}
        </p>
      </v-col>
      <v-col class="d-flex align-end justify-center">
        <div>
          <v-btn
            variant="text"
            color="white"
            class="button"
            @click="() => useCookiePreferences({ googleAnalytics: true, marketing: true })"
          >
            {{ $t('accept') }}</v-btn
          >
          <v-btn
            variant="text"
            color="white"
            class="button"
            @click="() => useCookiePreferences({ googleAnalytics: false, marketing: false })"
            >{{ $t('deny') }}</v-btn
          >
          <v-btn variant="text" color="white" class="button"
            >{{ $t('cookie.more_information') }}
            <cookie-dialog @on-preference-save="useCookiePreferences" />
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.cookie-banner {
  background-color: #4344cf;
  z-index: 999;
  position: sticky;
  bottom: 0;
}
.highlighted {
  font-size: 20px;
  font-weight: 700;
}
.paragraph {
  font-size: 16px;
  line-height: 1.5;
}
.button {
  text-transform: none !important;
}
</style>
