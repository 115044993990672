import { CompensationTypes } from '@/enumerators/enumerators'
import type { Compensation } from '@/interfaces/interfaces'

export const ROUTE_NAMES = {
  HOME: 'home',
  REGISTER: 'register',
  LOGIN: 'login',
  CHANGE_PASSWORD: 'change-password',
  NOT_VERIFIED: 'not-verified-email',
  DISTANCE_VERIFICATION: 'distance-verification',
  FLIGHT_IDENTIFICATION: 'flight-identification',
  PASSENGER_DETAILS: 'passenger-details',
  REASONS_APPLICATION: 'reasons-application',
  DOCUMENTS: 'upload-documents',
  PROFILE: 'edit-profile',
  RESET_PASSWORD: 'reset-password',
  FORGOT_PASSWORD: 'forgot-password',
  APPLICATION_DETAIL: 'ziadost',
  PERSONAL_DATA: 'personal_data',
  DONE_APPLICATION: 'application-done'
}

export const COMPENSATION_TYPES: Array<Compensation> = [
  {
    name: CompensationTypes.delayed,
    id: 1,
    options: [
      {
        name: 'shorter_than_3_hours',
        id: 2
      },
      {
        name: 'longer_than_3_hours',
        id: 3
      },
      {
        name: 'missed_connecting_flight',
        id: 4
      }
    ]
  },
  {
    name: CompensationTypes.cancelled,
    id: 5,
    options: [
      {
        name: 'duration_less_than_2_hours',
        id: 6
      },
      {
        name: 'duration_more_than_2_hours',
        id: 7
      },
      {
        name: 'duration_never_flied',
        id: 8
      }
    ]
  },
  {
    name: CompensationTypes.connectingCanceled,
    id: 9,
    options: []
  }
]

export const COMPENSATION_REASONS: Array<Compensation> = [
  {
    name: 'yes',
    id: 10,
    options: [
      {
        name: 'technical_problem',
        id: 17
      },
      {
        name: 'bad_weather',
        id: 18
      },
      {
        name: 'strike',
        id: 19
      },
      {
        name: 'airport_problem',
        id: 20
      },
      {
        name: 'other_flight',
        id: 21
      },
      {
        name: 'higher_power',
        id: 22
      },
      {
        name: 'other',
        id: 11
      }
    ]
  },
  {
    name: 'no',
    id: 2,
    options: []
  },
  {
    name: 'cant_remember',
    id: 3,
    options: []
  }
]

export const APP_NAV = [
  { code: 'my_claims', sef: '/' },
  { code: 'contact', sef: '/contact' },
  { code: 'settings', sef: '/settings' }
]

export const APP_NAV_LOGGED = [
  { code: 'contact', sef: '/personal-data?section=contact' },
  { code: 'bank', sef: '/personal-data?section=bank' },
  { code: 'change_password', sef: '/change-password' },
  { code: 'logout', sef: '/logout' }
]

export const NETWORK_ERROR_TYPES = [
  'ERR_NETWORK',
  'ECONNABORTED',
  'ENOTFOUND',
  'ECONNREFUSED',
  'ETIMEDOUT'
]
export const RETRY_AUTHORIZATION_PAGES = ['logout', 'login', 'auth/google']

export const DEFAULT_APPLICATION_VALUES = {
  distanceVerification: {
    fromAirport: null,
    toAirport: null,
    isDirect: true,
    restAirports: []
  },
  flightIdentification: {
    airline: null,
    flightNumber: '',
    flightDate: '',
    reservationNumber: ''
  },
  reasons: {
    flightProblem: null,
    flightProblemLength: null,
    reasonDelay: null,
    reasonDelayDetail: null,
    description: ''
  },
  passenger: {
    email: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    address: {
      street: '',
      city: '',
      country: '',
      postCode: ''
    },
    coPassengers: []
  }
}

export const EXTERNAL_STATUS_MAP = {
  1: 'created',
  2: 'in_validation',
  3: 'contract_documentation',
  4: 'airline_communication',
  5: 'court_communication',
  6: 'rejected',
  7: 'completed',
  8: 'stopped_documentation_needed',
  9: 'stopped_data_needed',
  10: 'rejected_out_of_europe',
  11: 'rejected_less_than_3_hours',
  12: 'closed_no_documents',
  13: 'closed_duplicate_claim'
}
