<script setup lang="ts">
import { ref, unref } from 'vue'
import Button from '@/components/Button.vue'

const emits = defineEmits(['onPreferenceSave'])

enum SAVE_TYPES {
  SAVE_ALL = 'SAVE_ALL',
  DISCARD_ALL = 'DISCARD_ALL',
  DEFAULT = 'DEFAULT'
}

const cookie = ref(true)
const analyticsCookie = ref(false)
const marketingCookie = ref(false)
const dialog = ref(false)

const saveCookies = (saveType: SAVE_TYPES) => {
  switch (saveType) {
    case SAVE_TYPES.SAVE_ALL:
      analyticsCookie.value = true
      marketingCookie.value = true
      break
    case SAVE_TYPES.DISCARD_ALL:
      analyticsCookie.value = false
      marketingCookie.value = false
  }
  const analytics = unref(analyticsCookie)
  const marketing = unref(marketingCookie)
  emits('onPreferenceSave', { googleAnalytics: analytics, marketing })
  dialog.value = false
}
</script>

<template>
  <v-dialog v-model="dialog" max-width="500" activator="parent">
    <v-card class="pa-4">
      <v-card-title class="d-flex justify-end">
        <Button
          :title="$t('close')"
          type="primary-blue"
          width="84px"
          :rounded="false"
          @click="dialog = false"
        />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h2>{{ $t('cookie.necessary_cookies') }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="cookie"
              color="#4344cf"
              density="compact"
              hide-details
              inline
              inset
              disabled
            >
              <template #label>
                <span
                  ><b class="text-uppercase">{{ $t('cookie.title') }}</b> -
                  {{ $t('cookie.description_warning') }}</span
                >
              </template>
            </v-switch>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h2>{{ $t('cookie.optional_cookies') }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-switch
              v-model="analyticsCookie"
              color="#4344cf"
              density="compact"
              hide-details
              inline
              inset
              :true-value="true"
            >
              <template #label>
                <span
                  ><b class="text-uppercase">{{ $t('cookie.analytics_title') }}</b> -
                  {{ $t('cookie.analytics_description') }}</span
                >
              </template>
            </v-switch>
            <v-switch
              v-model="marketingCookie"
              color="#4344cf"
              density="compact"
              class="pt-3"
              hide-details
              inline
              inset
            >
              <template #label>
                <span
                  ><b class="text-uppercase">{{ $t('cookie.marketing_title') }}</b> -
                  {{ $t('cookie.marketing_description') }}</span
                >
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
              :text="$t('save')"
              class="text-capitalize"
              variant="elevated"
              color="#4344cf"
              :rounded="false"
              @click="saveCookies"
            />
          </v-col>
          <v-col>
            <v-btn
              :text="$t('accept_all')"
              variant="elevated"
              class="text-capitalize"
              color="#4344cf"
              :rounded="false"
              @click="() => saveCookies(SAVE_TYPES.SAVE_ALL)"
            />
          </v-col>
          <v-col>
            <v-btn
              :text="$t('deny_all')"
              class="text-capitalize"
              :rounded="false"
              variant="elevated"
              color="#4344cf"
              @click="() => saveCookies(SAVE_TYPES.DISCARD_ALL)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
h2 {
  font-weight: bold;
  font-size: 24px;
  color: black;
}
</style>
