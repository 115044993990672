import { createRouter, createWebHistory, type RouteLocationNormalized } from 'vue-router'
import cookie from '@/utils/cookie'
import { useSiteStore } from '@/stores/site'
import { useCustomerStore } from '@/stores/customer'
import ApplicationsView from '@/views/ApplicationsView.vue'
import { ROUTE_NAMES } from '@/constants/constants'
import i18n from '../../locales/i18n.config'
import type { Customer } from '@/interfaces/interfaces'
import { useApplicationStore } from '@/stores/application'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (!['bank', 'contact'].includes(to.query?.section?.toString())) {
      return { top: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: ROUTE_NAMES.HOME,
      meta: {
        requiresAuth: true
      },
      component: ApplicationsView
    },
    {
      path: '/register',
      name: ROUTE_NAMES.REGISTER,
      meta: {
        hideNavbar: true
      },
      beforeEnter: (to, from, next) => {
        const isLoggedIn = cookie.get('isLoggedIn')
        if (isLoggedIn) {
          next({
            name: ROUTE_NAMES.HOME
          })
        } else {
          next()
        }
      },
      component: () => import('../views/AccountHandling/RegisterView.vue')
    },
    {
      path: '/login',
      name: ROUTE_NAMES.LOGIN,
      meta: {
        hideNavbar: true
      },
      component: () => import('../views/AccountHandling/LoginView.vue')
    },
    {
      path: '/change-password',
      name: ROUTE_NAMES.CHANGE_PASSWORD,
      meta: {
        requiresAuth: true,
        hideNavbar: true
      },
      component: () => import('../views/AccountHandling/ChangePasswordView.vue')
    },
    {
      path: '/not-verified-email',
      name: ROUTE_NAMES.NOT_VERIFIED,
      meta: {
        hideNavbar: true
      },
      component: () => import('../views/AccountHandling/NotVerifiedView.vue')
    },
    {
      path: '/distance-verification',
      name: ROUTE_NAMES.DISTANCE_VERIFICATION,
      component: () => import('../components/claimControl/DistanceVerification.vue')
    },
    {
      path: '/passenger-details',
      name: ROUTE_NAMES.PASSENGER_DETAILS,
      meta: {
        requiresAuth: false
      },
      component: () => import('../components/claimControl/PassengerDetail/PassengerDetail.vue')
    },
    {
      path: '/reasons-application',
      name: ROUTE_NAMES.REASONS_APPLICATION,
      component: () => import('../components/claimControl/ReasonsForApp.vue')
    },
    {
      path: '/application-done',
      name: ROUTE_NAMES.DONE_APPLICATION,
      component: () => import('../views/ClaimHandling/ApplicationDone.vue')
    },
    {
      path: '/upload-documents',
      name: ROUTE_NAMES.DOCUMENTS,
      component: () => import('../views/ClaimHandling/UploadDocumentsView.vue')
    },
    {
      path: '/personal-data',
      name: ROUTE_NAMES.PERSONAL_DATA,
      meta: {
        requiresAuth: false
      },
      component: () => import('../views/PersonalDataView.vue')
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: async (to, from, next) => {
        const userStore = useCustomerStore()
        await userStore.logout()
        cookie.remove('isLoggedIn')
        const applicationStore = useApplicationStore()
        applicationStore.clearApplication()
        next({
          name: ROUTE_NAMES.LOGIN
        })
      },
      component: () => import('../views/AccountHandling/LoginView.vue')
    },
    {
      path: '/reset-password',
      name: ROUTE_NAMES.RESET_PASSWORD,
      meta: {
        hideNavbar: true
      },
      beforeEnter: (to, from, next) => {
        const siteStore = useSiteStore()
        if (to.query.token) {
          next()
        } else {
          siteStore.showSnackbar('Token is not valid!', 'error')
          next({
            name: ROUTE_NAMES.LOGIN
          })
        }
      },
      component: () => import('../views/AccountHandling/ResetPasswordView.vue')
    },
    {
      path: '/forgot-password',
      name: ROUTE_NAMES.FORGOT_PASSWORD,
      meta: {
        hideNavbar: true
      },
      component: () => import('../views/AccountHandling/ForgotPasswordView.vue')
    }
  ]
})

router.beforeEach((to, from, next) => {
  const siteStore = useSiteStore()
  siteStore.setCurrentRoute(to.fullPath)

  let value = false
  if (to.meta.hideNavbar) {
    value = true
  }

  siteStore.setHideNavbar(value)

  next() // make sure to always call next()!
})

const handleBankAccount = (user: Customer | null, from: RouteLocationNormalized) => {
  if (!user) {
    return false
  }

  const skipBank = Boolean(localStorage.getItem('skipBank'))

  return from.name !== ROUTE_NAMES.PERSONAL_DATA && user.sign_in_count <= 1 && !skipBank
}

router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const userStore = useCustomerStore()
    // this route requires auth, check if logged in
    const isLoggedIn = cookie.get('isLoggedIn')
    if (!isLoggedIn) {
      // if not, redirect to login page.
      userStore.user = null // make sure to clear user data if cookie is not present
      if (to.meta.requiresAuth) {
        const siteStore = useSiteStore()
        siteStore.showSnackbar(i18n.global.t('error_messages.login_again'), 'warning')
      }
      next({
        name: to.meta.requiresAuth ? ROUTE_NAMES.LOGIN : ROUTE_NAMES.DISTANCE_VERIFICATION
      })
    } else {
      // if first login then redirect to personal data bank account
      if (handleBankAccount(userStore.getUser, from)) {
        next({
          //@ts-ignore
          name: ROUTE_NAMES.PERSONAL_DATA,
          query: { section: 'bank', firstTime: true }
        })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
