<template>
  <v-app>
    <ClientZoneNav v-if="!hideNavDrawer" />
    <Loader />
    <v-main :class="{ 'main-view': showAppFooter }">
      <RouterView />
      <Footer v-if="showAppFooter" />
    </v-main>
    <Snackbar />
    <CookieControl />
  </v-app>
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router'
import Snackbar from '@/components/snackbar/Snackbar.vue'
import { computed } from 'vue'
import { useSiteStore } from '@/stores/site'
import Footer from '@/components/footer/Footer.vue'
import Loader from '@/components/loader/Loader.vue'
import ClientZoneNav from '@/components/navigation/ClientZoneNav.vue'
import CookieControl from '@/components/common/cookieControl/cookieControl.vue'

const siteStore = useSiteStore()

const hideNavDrawer = computed((): boolean => {
  return siteStore.getHideNavbar
})

const showAppFooter = computed((): boolean => {
  return ![
    '/login',
    '/register',
    '/change-password',
    '/not-verified-email',
    '/reset-password',
    '/forgot-password'
  ].includes(siteStore.getCurrentRoute)
})
</script>

<style scoped>
.main-view {
  min-height: 100vh;
  @media (min-width: 1280px) {
    padding-top: 80px;
  }
}

.hide-nav-mobile {
  @media only screen and (min-width: 1280px) {
    display: none;
  }
}
.v-application {
  background: #fff;
}
</style>
