<script setup lang="ts"></script>

<template>
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="40" height="40" rx="20" fill="white" />
    <g clip-path="url(#clip0_176_400)">
      <path
        d="M30.2283 8H9.77167C9.30179 8 8.85116 8.18666 8.51891 8.51891C8.18666 8.85116 8 9.30179 8 9.77167V30.2283C8 30.6982 8.18666 31.1488 8.51891 31.4811C8.85116 31.8133 9.30179 32 9.77167 32H30.2283C30.6982 32 31.1488 31.8133 31.4811 31.4811C31.8133 31.1488 32 30.6982 32 30.2283V9.77167C32 9.30179 31.8133 8.85116 31.4811 8.51891C31.1488 8.18666 30.6982 8 30.2283 8ZM15.1533 28.445H11.545V16.9833H15.1533V28.445ZM13.3467 15.395C12.9374 15.3927 12.5379 15.2692 12.1987 15.0401C11.8596 14.811 11.5958 14.4865 11.4409 14.1077C11.2859 13.7288 11.2466 13.3126 11.328 12.9115C11.4094 12.5103 11.6078 12.1423 11.8982 11.8538C12.1885 11.5653 12.5578 11.3693 12.9595 11.2905C13.3611 11.2117 13.7771 11.2536 14.155 11.411C14.5328 11.5684 14.8555 11.8342 15.0825 12.1748C15.3094 12.5155 15.4303 12.9157 15.43 13.325C15.4339 13.599 15.3825 13.871 15.279 14.1248C15.1755 14.3786 15.022 14.6089 14.8276 14.8021C14.6332 14.9952 14.4018 15.1473 14.1474 15.2492C13.893 15.351 13.6207 15.4006 13.3467 15.395ZM28.4533 28.455H24.8467V22.1933C24.8467 20.3467 24.0617 19.7767 23.0483 19.7767C21.9783 19.7767 20.9283 20.5833 20.9283 22.24V28.455H17.32V16.9917H20.79V18.58H20.8367C21.185 17.875 22.405 16.67 24.2667 16.67C26.28 16.67 28.455 17.865 28.455 21.365L28.4533 28.455Z"
        fill="#3E3CAA"
      />
    </g>
    <defs>
      <clipPath id="clip0_176_400">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped></style>
