<script setup lang="ts"></script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M17.2137 13.3278L17.8356 9.23262H13.9452V6.57631C13.9452 5.45568 14.4877 4.36272 16.2301 4.36272H18V0.876308C18 0.876308 16.3945 0.599609 14.8603 0.599609C11.6548 0.599609 9.56164 2.56141 9.56164 6.11145V9.23262H6V13.3278H9.56164V23.2281C10.2767 23.3415 11.0082 23.3996 11.7534 23.3996C12.4986 23.3996 13.2301 23.3415 13.9452 23.2281V13.3278H17.2137Z"
      fill="#3E3CAA"
    />
  </svg>
</template>

<style scoped></style>
