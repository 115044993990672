export default {
  error_messages: {
    default_error: 'Něco se pokazilo',
    access_token_expired: 'Vaše relace vypršela. Prosím, přihlaste se znovu.',
    claim_not_found: 'Požadavek nebyl nalezen',
    not_verified_email: 'Váš email není ověřen. Prosím, ověřte váš email.',
    unknown_email: 'Neznámý email',
    email_occupied: 'Email je již obsazen',
    different_new_passwords: 'Nová hesla se liší',
    could_not_validate_credentials: 'Nepodařilo se ověřit přihlašovací údaje',
    incorrect_email_or_password: 'Nesprávný email nebo heslo',
    weak_password: 'Slabé heslo',
    invalid_reset_token: 'Neplatný token pro resetování',
    incorrect_old_password: 'Nesprávné staré heslo',
    not_found: 'Záznam s daným identifikátorem neexistuje',
    not_authorized: 'Nejste oprávněn provést tuto akci',
    entity_too_large: 'Příloha je příliš velká',
    url_already_exists: 'URL již existuje',
    recaptcha_error: 'Chybná Recaptcha',
    login_again: 'Please log in again'
  },
  distance_verification_subtitle: 'Informace slouží pro kontrolu, zda máte nárok na kompenzaci.',
  distance_verification_flight_from: 'Odlet z',
  distance_verification_flight_to: 'Přílet do',
  no_results: 'Nenašli se žádné výsledky',
  enter_city_airport: 'Zadej město nebo letiště',
  distance_verification_direct_flight: 'Měli jste přímý let?',
  distance_verification_stepovers: 'Kde se nacházel váš přestup?',
  distance_verification_stepovers_other: 'Kde se nacházel váš další přestup?',
  next: 'Dále',
  previous: 'Zpět',
  yes: 'Ano',
  no: 'Ne',
  passenger_details: 'Údaje o pasažérech',
  flight_identification_airline: 'Název letecké společnost',
  flight_identification_number: 'Váš let',
  flight_identification_number_placeholder: 'Např. NQH5UX',
  flight_identification_date: 'Datum letu',
  flight_identification_reservation_number: 'Rezervační číslo',
  flight_identification_reservation_number_placeholder: 'Např. FR1234',
  flight_identification_alert:
    'Váš potvrzovací kód je v e-mailu, který jste obdrželi od letecké společnosti po rezervaci letu. Tento kód je také viditelný na vaší palubní kartě. Vypadá takto: AB12CD (šestimístné slovo složené z písmen a číslic). V případě charterového letu vyplňte 000000 (šest nul).',
  reasons_application_title: 'Důvody žádání kompenzaci',
  reasons_application_subtitle:
    'Doplňkové informace, které nám mohou pomoci k rychlejšímu vyřízení kompenzace.',
  reasons_application_problem: 'Jaký byl problém letu, se kterým jste se setkali?',
  reasons_application_problem_label: 'Zvol problém',
  reasons_application_interval: 'Kolik hodin trvalo Vaše zpoždění letu?',
  reasons_application_interval_label: 'Vyber možnost',
  reasons_application_problem_type: 'Oznámila vám společnost důvod zpoždění/zrušení letu?',
  reasons_application_airport_problem: 'Jaký byl problém letu, se kterým jste se setkali?',
  reasons_application_problem_description: 'Ve zkratce popište svůj zážitek',
  reasons_application_problem_description_label: 'Se skými nepříjemnostmi jste se setkali?',
  reasons_application_agreement_1: 'Souhlasím se',
  reasons_application_agreement_2: 'zpracováním mých osobních údajů.',
  reasons_application_agreement_2_general: 'všeobecnými obchodními podmínkami',
  upload_documents: 'Dokumenty',
  upload_documents_title: 'Dokumenty k ověření nároku',
  upload_documents_load_docs: 'Nahrajte dokument',
  upload_documents_load_docs_label: 'Vyberte soubor',
  upload_documents_btn_later: 'Doplním později',
  files: 'soubory',
  documents_1: 'Letenka resp. palubní lístek (boarding pass) / všichni cestující',
  documents_1_help:
    'Pokud jste zadali do žádosti více cestujících, potřebujeme letenky od všech cestujících pro dotyčný let.',
  documents_2: 'Potvrzení z letiště o zpoždění/zrušení letu',
  documents_2_help:
    'Jedná se o dokument vystavený letištěm na požádání. Nejedná se o vyfocenou informační tabuli a pod. K tomu slouží řádek "Další dokumenty"',
  documents_3: 'Komunikace s leteckou společností',
  documents_3_help:
    'Kompletní e-mailovou komunikaci a smluvní dokumentaci s leteckou společností ve věci Vašeho letu a také prodeje letenky, pokud jste si ji zakoupili přímo od letecké společnosti, včetně obchodních podmínek.',
  documents_4: 'Komunikace s cestovní kanceláří',
  documents_4_help:
    'Kompletní e-mailovou komunikaci a smluvní dokumentaci s prodejcem letenky/zájezdu (např. cestovní kancelář, portál apod.), pokud jste si ji přímo nezakoupili od letecké společnosti, včetně obchodních podmínek.',
  documents_5: 'Komunikace se společností, zabývající se odškodněním',
  documents_5_help:
    'Kompletní e-mailovou komunikaci, pokud jste již případ řešili s jinou společností, zabývající se získáním kompenzace za Váš zrušený let.',
  documents_6: 'Ostatní dokumenty',
  documents_6_help: 'Ostatní dokumenty týkající se Vašeho případu',
  documents_other: 'Další dokumenty',
  documents_other_help:
    'Zde nahrajte jakýkoli další dokument/fotku, který nebyl výše zmíněn a je podle Vás důležitý.',
  download: 'Stáhnout',
  card_flight_airline: 'Letecká společnost',
  card_flight_number: 'Číslo letu',
  card_flight_date: 'Datum letu',
  card_personal_name: 'Jméno',
  card_personal_last_name: 'Příjmení',
  card_personal_email: 'Email',
  card_personal_date: 'Datum narození',
  card_personal_country: 'Země',
  card_personal_street: 'Ulice',
  card_personal_city: 'Město',
  card_personal_postal: 'PSČ',
  card_personal_contact: 'Telefonní číslo',
  login: 'Přihlášení',
  my_account: 'Klientská zóna',
  application_progress: 'Proces podání žádosti',
  add: 'Přidat',
  remove: 'Odebrat',
  password_change: 'Změna hesla',
  password: 'Heslo',
  new_password: 'Nové heslo',
  new_password_confirm: 'Potvrzení nového hesla',
  data_was_updated: 'Data byla úspěšně uložena',
  password_confirm: 'Potvrzení hesla',
  forgot_password: 'Zapomněl jsem heslo',
  forgot_password_description:
    'Pro resetování hesla zadejte emailovou adresu, na kterou vám bude zaslán ověřovací email.',
  forgot_password_email: 'Odeslat ověřovací e-mail',
  forgot_password_email2: 'E-mail byl odeslán na adresu:',
  confirm_email_alert: 'Úspěšně jste potvrdili email. Pokračujte prosím přihlášením.',
  login2: 'Přihlásit se',
  or_use: 'nebo použij',
  forgot_password_question: 'Zapomněl jsi heslo?',
  restore: 'Obnovit',
  no_account_question: 'Ještě nemáš účet?',
  register: 'Registrovat se',
  confirm_email_fail: 'Link na potvrzení vypršel. Požádejte o nový.',
  just_a_step: 'Už jen jeden krok!',
  not_verified_description:
    'Pro dokončení procesu registrace je třeba potvrdit email. Pokud vám nepřišel e-mail, můžete požádat o znovuposlání.',
  already_have_account: 'Už máš účet?',
  create_password: 'Vytvoření hesla',
  restore_password: 'Obnovení hesla',
  set_password: 'Nastavit heslo',
  reset_password_change: 'Změnit heslo',
  reset_password_success_alert_1: 'Podařilo se vám úspěšně nastavit heslo.',
  reset_password_success_alert_2: 'Podařilo se vám úspěšně změnit heslo.',
  adult_rule: 'Za osoby mladší 18 let, podává žádost jejich zákonný zástupce.',
  same_email_rule: 'Email byl již použit.',
  are_not_same_rule: 'Emaily se neshodují',
  email_confirm: 'Potvrzení emailu',
  repeat_email: 'Zopakuj email',
  contact_info: 'Kontaktní údaje',
  other_passengers: 'Další cestující',
  application_state: 'STAV žádosti:',
  my_claims_state: 'Stav žádosti:',
  edit: 'Editovat',
  save: 'Uložit',
  shorter_than_3_hours: 'Kratší než 3 hodiny',
  longer_than_3_hours: 'Delší než 3 hodiny',
  missed_connecting_flight: 'Zmeškání návazného letu',
  duration_less_than_2_hours: '0 - 2 hodiny',
  duration_more_than_2_hours: 'Více než 2 hodiny',
  duration_never_flied: 'Nikdy neodletěl',
  technical_problem: 'Technický problém',
  bad_weather: 'Špatné počasí',
  strike: 'Stávka',
  airport_problem: 'Problém na letišti',
  other_flight: 'Ovlivnění jinými lety',
  higher_power: 'Vyšší moc',
  other: 'Jiné',
  cant_remember: 'Nepamatuji si',
  logout: 'Odhlásit se',
  upload: 'Nahrát',
  faq: 'Faq',
  blog: 'Blog',
  new_traveler: 'Přidat dalšího cestujícího',
  flight_delay: 'Zpoždění letu',
  flight_cancel: 'Zrušený let',
  flight_connecting_delay: 'Zpoždění navazujícího letu',
  claim_submission: 'Podání žádosti',
  my_claims: 'Mé žádosti',
  claim_stepper: {
    mobile: {
      title: '$t(claim_submission)',
      step1: 'Ověření letu',
      step2: 'Údaje o cestujícím',
      step3: 'Dokončení'
    }
  },
  validations: {
    required: 'Povinné pole',
    max_char: 'Maximální počet znaků je',
    different_password: 'Hesla se neshodují',
    password_format: 'Heslo musí obsahovat alespoň 8 znaků, velké písmeno, malé písmeno a číslici',
    postal_code: 'Zadejte platné PSČ',
    email: 'Zadejte platný email',
    age: 'Minimální věk je',
    dateFormat: 'Datum musí být ve formátu DD. MM. RRRR',
    dateValidity: 'Neplatné datum',
    dateFuture: 'Datum nemůže být v budoucnosti.'
  },
  alert_messages: {
    halted_missing_docs: ['Pozastaveno postupování', 'Chybějící dokumenty'],
    halted_missing_data: ['Pozastaveno postupování', 'Chybějící údaje'],
    claim_validation: ['Žádost v procesu', 'Ověření nároku'],
    missing_claim_attachments: ['Žádost v procesu', 'Chybějící přílohy'],
    missing_legal_documents: ['Žádost v procesu', 'Chybějící právní dokumenty'],
    legal_documents: ['Žádost v procesu', 'Ověřujeme právní dokumenty'],
    communications_airline: ['Žádost v procesu', 'Komunikace s leteckou společností'],
    communications_court: ['Žádost v procesu', 'Komunikace se soudem'],
    successfully_completed: ['Žádost ukončena', 'Úspěšně ukončena'],
    closed_undelivered_documents: ['Žádost ukončena', 'Nedodané dokumenty'],
    closed_duplicate_request: ['Žádost ukončena', 'Duplicitní žádost'],
    extraordinary_situation: ['Žádost ukončena', 'Mimořádná událost'],
    delay_less_then_3_hours: ['Žádost ukončena', 'Zpoždění kratší než 3 hodiny'],
    flight_outside_eu: ['Žádost ukončena', 'Let mimo EU'],
    claim_successfully_completed: ['Žádost ukončena', 'Úspěšně ukončena'],
    closed_duplicated_request: ['Žádost ukončena', 'Duplicitní žádost'],
    closed_undelivered_docs: ['Žádost ukončena', 'Nedoručené dokumenty'],
    court_start_process: ['Soudní proces', 'Návrh na zahájení'],
    court_received_docs: ['Soudní proces', 'Přijaté dokumenty'],
    court_lawyer_delegation: ['Soudní proces', 'Odevzdání případu advokátní kanceláří']
  },
  claim_status: {
    created: 'Vytvořena',
    validation: 'Ověření nároku',
    legal_documents: 'Zasmluvnění stran',
    arline_communications: 'Komunikace s leteckou společností',
    court_communication: 'Komunikace se soudem',
    rejected: 'Zamítnutá žádost - Mimořádná událost',
    completed: 'Úspěšně ukončena',
    finished: 'Zavření žádosti',
    stopped_documentation_needed: 'Pozastavené pokroky - Chybějící dokumenty',
    stopped_data_needed: 'Pozastavené pokroky - Chybějící údaje',
    court_communications: 'Komunikace se soudem'
  },
  confirm: 'Potvrdit',
  claim_success_message:
    'Žádost byla úspěšně odeslána. Stav Vaší žádosti si prosím sledujte v záložce Moje žádosti',
  contact_message_success: 'Úspěšně jste odeslali kontaktní formulář. Děkujeme.',
  footer: {
    submit_claim_slogan: 'U nás platíte jen v případě úspěšného získání odškodného.',
    our_services: 'Naše služby',
    delay_longer_than_3_hours: 'Zpoždění letu více než 3 hodiny',
    canceled_flight: 'Zrušení letu bez informování předem',
    delayed_connecting_flight: 'Zpoždění návazného letu',
    delay_longer_than_3_hours_url: '/za-zmeskani-letu-muzete-ziskat-odskodne-az-do-vysky-600-e',
    canceled_flight_url: '/neocekavane-zruseni-letu-mozno-mate-narok-na-odskodne-az-do-vysky-600-e',
    delayed_connecting_flight_url:
      '/za-zmeskany-navazny-let-si-mozno-uplatnit-odskodne-az-do-vysky-600-e',
    customer_service_message:
      'Buďte s námi v kontaktu. Sledujte nás na sociálních sítích, kde naleznete novinky, jedinečné články a více informací o vašich nárocích!',
    customer_service_title: 'Spojte se s námi',
    company_slogan:
      'Pomáháme klientům získávat odškodnění v letecké přepravě za zrušení a zpoždění letu s odborným týmem. Vyplňte online žádost a zaplatíte jen v případě úspěchu.',
    company_email: "info{'@'}meskanieletu.sk",
    company_name: 'Studio JŠ, s.r.o. - Meskanieletu.sk',
    company_address: 'Ul. 1. Mája, Krásno nad Kysucou 02302, Slovensko',
    lawyer_documents: {
      title: 'Právní dokumenty',
      general_terms: 'Obchodní podmínky',
      privacy_policy: 'Ochrana osobních údajů',
      cookies_files: 'Nařízení EPaR (ES) č. 261/2004'
    },
    menu: {
      title: 'Menu',
      about_us: 'O nás',
      price_list: 'Ceník',
      faq: 'FAQ',
      blog: 'Blog',
      urls: {
        about_us: '/o-nas',
        price_list: '/ceník',
        faq: '/casto-kladene-otazky',
        terms_and_conditions: '/vseobecne-obchodne-podmínky',
        privacy_policy: '/ochrana-a-zpracovani-osobnich-udaju',
        blog: '/blog'
      }
    }
  },
  buttons: {
    submit_claim: 'Podat žádost',
    logout: 'Odhlásit se',
    login: 'Přihlásit se',
    check_claim: 'Ověřit nárok',
    skip: 'Přeskočit'
  },
  navbar: {
    my_claims: 'Mé žádosti',
    contact: 'Kontaktní údaje',
    bank: 'Bankovní údaje',
    settings: 'Nastavení',
    show_profile: 'Zobrazit profil',
    change_password: 'Změnit heslo',
    logout: 'Odhlásit se'
  },
  personal_data: 'Osobní údaje',
  personal_data_view: {
    title: 'Vaše údaje',
    description:
      'Prosím zkontrolujte si své osobní kontaktní údaje. Díky těmto informacím bude vyřizování vaší žádosti rychlejší a jednodušší. Vaše údaje nám umožní automaticky předvyplnit smlouvy nebo v případě potřeby Vás kontaktovat. Děkujeme za vaši spolupráci!',
    bank_account_title: 'Bankovní účet',
    bank_account_description:
      'Informace o bankovním účtu slouží pro potřeby přípravy smluvní dokumentace a rovněž k zaslání finanční částky v rozsahu příkazní smlouvy, v případě uspěšné kompenzace.',
    iban_title: 'Číslo účtu ve tvaru IBAN'
  },
  address: 'Adresa bydliště',
  address_line_2: 'Adresa řádek 2',
  city: 'Město',
  postal_code: 'PSČ',
  country: 'Země',
  phone_number: 'Tel. číslo',
  bank_account_owner: 'Jméno vlastníka účtu',
  iban: 'IBAN',
  swift: 'SWIFT',
  airplaneBackgroundText:
    'Pro více detailů o své žádosti se přihlaste do svého účtu po podání žádosti.',
  cancel: 'Zrušit',
  application_done_title: 'Máme všechno co potřebujeme!',
  application_done_subtitle:
    'Posoudíme vaši žádost na odškodnění a pošleme vám informace o dalším postupu na váš e-mail. Aktuální stav vaší žádosti můžete sledovat ve svém účtu. Více informací již máte v e-mailu.',
  add_document: 'Přidat dokument',
  reprimand_contract: 'Příkazní smlouva',
  delegation_document: 'Zmocnění',
  my_claims_view: {
    personal_information: 'Osobní údaje žadatele',
    departure_place: 'Místo odletu',
    arrival_place: 'Místo příletu',
    claim: 'Žádost',
    claim_number: 'ČÍSLO ŽÁDOSTI',
    requestor: 'Jméno žadatele',
    submission_date: 'Datum podání',
    all_documents_requirement: 'Je třeba odeslat všechny dokumenty.',
    upload_documents: 'Nahrát dokumenty',
    here: 'ZDE',
    claim_type: 'Typ žádosti',
    title: 'Podání žádosti rychle a jednoduše!',
    fast_and_easy: 'Rychlé a jednoduché',
    fast_and_easy_neutral: 'rychle a jednoduše!',
    all_airlines: 'Všechny aerolinky',
    no_risks: 'Bez rizika poplatků',
    fast_handling: 'Zabere to jen 3 min.',
    show_more: 'Zobrazit detail žádosti',
    show_less: 'Zobrazit méně',
    residence: 'Bydliště',
    email_address: 'Emailová adresa',
    claim_status: 'Aktuální stav žádosti',
    other_passengers: 'Další pasažéři',
    description:
      'Náš tým profesionálů z oblasti cestovního práva prověří vaši žádost a průběžně Vás bude informovat o každém dosaženém milníku celého procesu.',
    day_of_flight: 'Den letu'
  },
  IdOrPass: 'Občanský průkaz (přední strana) nebo cestovní pas',
  claimDocuments: 'Dokumenty k nároku',
  representationDocuments: 'Dokumenty k zastupování',
  deleteConfirmationText: 'K dokumentu se už nebudete umět dostat. Dokument se trvale vymaže.',
  deleteConfirmationTitle: 'Opravdu si přejete tento dokument vymazat?',
  coPassengerNumber: 'Cestující čj.',
  unknownFile: 'Neznámý soubor',
  approvedByAdmin: 'Odebrané správcem',
  missingDocuments: 'Chybějící dokumenty',
  sentForValidation: 'Odesláno k ověření',
  IdontHave: 'Nemám',
  other_airline_communication:
    'Komunikovali jste s leteckou společností nebo jinou společností pro získání kompenzace?',
  yes_communicated: 'Ano, komunikovali jsme',
  no_communication: 'Ne, nekomunikovali jsme',
  communication: 'Stručný popis komunikace',
  explain_communication:
    'Zde stručně popište svou komunikaci s leteckou společností nebo společností zabývající se získáním odškodného',
  send_without_documentation: 'Odeslat bez dokumentů',
  send: 'Odeslat',
  continue_to_detail: 'Přejdi na detail žádosti',
  rest_upload_later: 'Zbytek doplním později',
  delete: 'Vymazat',
  reprimand_contract_description:
    'Příkazní smlouva je smlouva, kterou podepisujete s naší společností.',
  delegation_document_description:
    'Zplnomocnění je dokument, kterým nám dovolujete zastupovat vás v rámci žádosti.',
  idOrPass_description: 'Občanský průkaz nebo cestovní pas je potřebný pro ověření totožnosti.',
  step_number: 'Krok č.',
  cookie: {
    description:
      'Používáme soubory cookie a soubory cookie třetích stran, abychom mohli vše správně zobrazit a lépe pochopit, jak používáte tyto webové stránky, abychom mohli zlepšit nabízené služby. Rozhodnutí můžete kdykoli změnit pomocí tlačítka cookie, které se zobrazí po provedení výběru na tomto banneru.',
    more_information: 'Další informace a přizpůsobení',
    necessary_cookies: 'Nezbytné cookies',
    optional_cookies: 'Volitelné cookies',
    marketing_description: 'Marketingové účely',
    marketing_title: 'Marketingové účely',
    analytics_title: 'Google Analytics',
    analytics_description: 'Sledování návštěvnosti stránky',
    title: 'Cookies',
    description_warning: 'Tato stránka používá soubory cookie ke zlepšení uživatelského zážitku'
  },
  accept: 'Přijmout',
  deny: 'Odmítnout',
  close: 'Zavřít',
  accept_all: 'Přijmout všechny',
  deny_all: 'Odmítnout všechny',
  add_copassenger: 'Přidat spolucestujícího',
  unsaved_copassenger_explanation:
    'Máte neuložené změny. Uložte prosím své změny nebo je zrušte před opuštěním formuláře.'
}
